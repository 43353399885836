/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { orgHierarchyQuery } from '@se/data/orgHierarchy/query/index.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { LabelButton } from '@seeeverything/ui.primitives/src/components/LabelButton/LabelButton.tsx';
import { LoadMore } from '@seeeverything/ui.primitives/src/components/LoadMore/LoadMore.tsx';
import { OutsideAlerter } from '@seeeverything/ui.primitives/src/components/OutsideAlerter/OutsideAlerter.tsx';
import {
  IListItemLabel,
  ISelectionListItem,
  SelectionListClickEvent,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import Spinner from '@seeeverything/ui.primitives/src/components/Spinner/Spinner.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useGraphQL } from '@seeeverything/ui.util/src/graphql/GraphQLProvider.tsx';
import {
  keepPreviousData,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DistributionListRuleType,
  DistributionListTeamRuleDetail,
} from '../../../redux/distributionList/types.ts';
import { editDistributionListSlice } from '../../../redux/editDistributionList/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../../redux/store.ts';
import { RuleOptionsSelectList } from './RuleOptionsSelectList.tsx';
import { RuleRowButton } from './RuleRowButton.tsx';

export interface ITeamsBelowContainerProps {
  ruleId: string;
}

export const TeamsBelowContainer: React.FC<ITeamsBelowContainerProps> = ({
  ruleId,
}) => {
  const dispatch = useFormsDispatch();
  const client = useGraphQL();

  const isEditable = useFormsSelector(
    (state) => state.formEditDistributionList.draft.canEdit === true,
  );

  const rule = useFormsSelector((state) =>
    state.formEditDistributionList.draft.rules?.find((r) => r.id === ruleId),
  );

  const tenantConfig = useFormsSelector(
    (state) => state.tenantState.tenant.configuration,
  );

  const error = useMemo(() => rule?.errors, [rule?.errors]);

  const [filter, setFilter] = useState('');

  const {
    data: teamsData,
    isLoading: isLoadingTeams,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    placeholderData: keepPreviousData,
    queryKey: [{ key: 'TeamsBelow', filter, tenantConfig }],
    queryFn: async ({ pageParam, queryKey }) => {
      const response = await orgHierarchyQuery.getTeams(client, {
        pageNumber: pageParam,
        name: queryKey[0].filter,
        tenantConfig: queryKey[0].tenantConfig,
        excludeNonParentTeams: true,
      });
      return response.isSuccess ? response.data : null;
    },
    getNextPageParam: (latestPage) => {
      if (!latestPage) return null;
      if (!latestPage.pageInfo.hasNextPage) return null;
      return latestPage.pageInfo.currentPage + 1;
    },
    initialPageParam: 1,
  });

  const selectedTeam = useMemo(
    () => rule?.selections?.[0] as DistributionListTeamRuleDetail,
    [rule?.selections],
  );

  const selectedLevel = useMemo(
    () => selectedTeam?.hierarchyLevel,
    [selectedTeam?.hierarchyLevel],
  );

  const { data: levelData, isFetching: isLoadingLevels } = useQuery({
    placeholderData: keepPreviousData,
    enabled: Boolean(selectedTeam),
    queryKey: [
      {
        key: 'teams.hierarchyLevels',
        tenantConfig,
        path: selectedTeam?.path,
      },
    ],
    queryFn: async ({ queryKey }) => {
      const response = await orgHierarchyQuery.getTeamHierarchyLevels(client, {
        path: queryKey[0].path,
        tenantConfig: queryKey[0].tenantConfig,
      });
      return response.isSuccess ? response.data : null;
    },
  });

  useEffect(() => {
    if (!selectedTeam) return;
    if (selectedLevel) return;
    if (isLoadingLevels) return;
    if (!levelData?.length) return;

    dispatch(
      editDistributionListSlice.updateRuleRow({
        id: ruleId,
        type: DistributionListRuleType.IncludeTeamsBelow,
        selections: [
          {
            id: selectedTeam.id,
            name: selectedTeam.name,
            path: selectedTeam.path,
            hierarchyLevel: levelData[0],
          },
        ],
      }),
    );
  }, [
    dispatch,
    isLoadingLevels,
    levelData,
    ruleId,
    selectedLevel,
    selectedTeam,
  ]);

  const teamSelections = useMemo(() => {
    if (!teamsData?.pages) return;

    const results = teamsData.pages
      .filter(Boolean)
      .map((page) => page.teams)
      .flat()
      .map(
        (t): ISelectionListItem => ({
          id: t.id,
          content: { text: t.name, description: t.path, maxWidth: 248 },
          value: t.id,
          icon: Icons[t.businessUnit.icon],
        }),
      );

    if (hasNextPage) {
      results.push({
        id: 'load-more',
        content: <LoadMore onInView={fetchNextPage} />,
      });
    }
    return results;
  }, [teamsData?.pages, fetchNextPage, hasNextPage]);

  const levelSelections = useMemo(
    () =>
      levelData?.map(
        (level, index): ISelectionListItem => ({
          id: level,
          content: {
            text: `Level ${level}`,
            description: index === 0 ? 'Directly Below' : '',
            dataTest: `hierarchy-level-item-${level}`,
          },
          value: level,
        }),
      ),
    [levelData],
  );

  const [isShowingTeamsDropdown, setIsShowingTeamsDropdown] = useState(false);

  const handleLevelSelect = useCallback(
    (to: ISelectionListItem) => {
      if (!selectedTeam) return;

      dispatch(
        editDistributionListSlice.updateRuleRow({
          id: ruleId,
          type: DistributionListRuleType.IncludeTeamsBelow,
          selections: [
            {
              id: selectedTeam.id,
              name: selectedTeam.name,
              path: selectedTeam.path,
              hierarchyLevel: to.id as number,
            },
          ],
        }),
      );
    },
    [dispatch, ruleId, selectedTeam],
  );

  const handleTeamClick = useCallback(
    (to: SelectionListClickEvent) => {
      const itemContent = to.item.content as IListItemLabel;

      dispatch(
        editDistributionListSlice.updateRuleRow({
          id: ruleId,
          type: DistributionListRuleType.IncludeTeamsBelow,
          selections: [
            {
              id: to.id.toString(),
              name: itemContent.text,
              hierarchyLevel: undefined,
              path: itemContent.description,
            },
          ],
        }),
      );

      setIsShowingTeamsDropdown(false);
    },
    [dispatch, ruleId],
  );

  const hasLevelValidationErrors = Boolean(error && !selectedLevel);
  const hasTeamValidationErrors = Boolean(error && !selectedTeam);

  return (
    <div css={styles.base}>
      <LabelButton
        bold={!hasTeamValidationErrors}
        capitalized={false}
        ellipsis={true}
        isEnabled={isEditable}
        label={selectedTeam?.name ?? 'Select Team'}
        maxWidth={130}
        onClick={() => setIsShowingTeamsDropdown(true)}
        theme={hasTeamValidationErrors ? 'ERROR' : undefined}
      />
      {isShowingTeamsDropdown && (
        <OutsideAlerter
          onClickedOutside={() => setIsShowingTeamsDropdown(false)}
        >
          <div css={styles.teamSelectionPopup}>
            <RuleOptionsSelectList
              isLoading={isLoadingTeams}
              items={teamSelections}
              filter={filter}
              onTeamSelected={handleTeamClick}
              onTeamFilterChanged={setFilter}
            />
          </div>
        </OutsideAlerter>
      )}
      {selectedTeam && <Text css={styles.levelLabel}>{'at'}</Text>}
      {selectedTeam && isLoadingLevels && <Spinner />}
      {selectedTeam && !isLoadingLevels && (
        <div css={styles.levelContainer}>
          <RuleRowButton
            dropdownItems={levelSelections}
            error={hasLevelValidationErrors ? error : undefined}
            isEnabled={isEditable}
            label={selectedLevel ? `Level ${selectedLevel}` : 'Select Level'}
            onSelect={handleLevelSelect}
            buttonWidth={'100%'}
          />
        </div>
      )}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  }),
  levelContainer: css({
    display: 'flex',
    flexDirection: 'row',
  }),
  levelLabel: css({
    fontSize: 13,
    padding: 5,
  }),
  teamSelectionPopup: css({
    backgroundColor: 'white',
    marginTop: 25,
    maxHeight: 250,
    position: 'absolute',
    top: 8,
    left: -100,
    zIndex: 99,
  }),
};
