/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useLocalStorage } from '@seeeverything/ui.primitives/src/hooks/useLocalStorage.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { StorageKey } from '@seeeverything/ui.util/src/storage/api.ts';
import { useCallback } from 'react';
import { AuthTokens } from './components/AuthTokens.tsx';
import { DebugSection } from './components/DebugSection.tsx';
import { GraphQLDebug } from './components/GraphQLDebug.tsx';
import { ReduxDebugContainer } from './components/ReduxDebug.tsx';
import { TenantDetails } from './components/TenantDetails.tsx';

export const DebugPanel: React.FC = () => {
  const [, setDebug] = useLocalStorage(StorageKey.Debug, true);

  const closeDebug = useCallback(() => {
    setDebug(false);
  }, [setDebug]);

  return (
    <div css={styles.base} className={'see-debug'}>
      <Button onClick={closeDebug} style={styles.button}>
        <Text style={styles.buttonText} cursor={'inherit'}>
          {'Close Debug Panel'}
        </Text>
      </Button>
      <DebugSection id={'tenant'} title={'Tenant Details'}>
        <TenantDetails />
      </DebugSection>
      <DebugSection id={'authTokens'} title={'Auth Tokens'}>
        <AuthTokens />
      </DebugSection>
      <DebugSection id={'redux'} title={'State (Redux)'}>
        <ReduxDebugContainer />
      </DebugSection>
      <DebugSection id={'graphql'} title={'GraphQL'}>
        <GraphQLDebug />
      </DebugSection>
    </div>
  );
};

const styles = {
  base: css({
    backgroundColor: 'white',
    width: '30%',
    minWidth: 450,
    display: 'flex',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    overflowX: 'hidden',
    overflowY: 'scroll',
    WebkitOverflowScrolling: 'touch',
    flexDirection: 'column',
    gap: 10,
    padding: '10px 15px',
  }),
  button: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 30,
    border: `solid 1px ${COLORS.BLUE}`,
    borderRadius: 3,
  }),
  buttonText: css({
    color: COLORS.BLUE,
  }),
};
