import { z } from 'zod';

const issuesAndInsights = z.object({
  type: z.enum(['Issues', 'LearningAreas', 'Highlights']),
});

export const dashboardIssuesAndInsightsSchema = {
  issuesAndInsights,
};

export type DashboardIssuesAndInsightsSchemaTypes = {
  IssuesAndInsights: z.infer<typeof issuesAndInsights>;
};
