/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  FormsBulkUploadValidateFileUploadRow,
  FormsBulkUploadWorkflowType,
} from '@se/data/forms/types.ts';
import { ContentPlaceholder } from '@seeeverything/ui.primitives/src/components/ContentPlaceholder/ContentPlaceholder.tsx';
import { MessageBar } from '@seeeverything/ui.primitives/src/components/MessageBar/MessageBar.tsx';
import Spinner from '@seeeverything/ui.primitives/src/components/Spinner/Spinner.tsx';
import { TabStrip } from '@seeeverything/ui.primitives/src/components/TabStrip/TabStrip.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { TenantLocale } from '@seeeverything/ui.util/src/redux/tenant/index.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import { useCallback, useMemo } from 'react';
import { FormsBulkUploadFileValidationResult } from '../../redux/formBulkUploadImportFile/formBulkUploadImportFileSlice.ts';
import { formBulkUploadImportFileSlice } from '../../redux/formBulkUploadImportFile/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../redux/store.ts';
import { toFormatArgs, tryStringFormat } from '../../util/util.bulkUpload.ts';
import { UploadTab } from './components/UploadTab.tsx';
import { ValidationResults } from './components/ValidationResults.tsx';
import { WorkflowTab } from './components/WorkflowTab.tsx';

export type BulkUploadFileImportDialogProps = {
  isLoading: boolean;
  validationResult?: FormsBulkUploadFileValidationResult;
  createAutomatedActions: boolean;
  includeAutomatedActions: boolean;
};

/**
 * The dialog content component for uploading and validating a Bulk Forms Processor file
 */
export const BulkUploadFileImportDialog: React.FC<
  BulkUploadFileImportDialogProps
> = ({
  isLoading,
  validationResult,
  createAutomatedActions,
  includeAutomatedActions,
}) => {
  const dispatch = useFormsDispatch();

  const fileSelected = useFormsSelector((state) =>
    Boolean(state.formBulkUploadImportFile.validatedFile),
  );

  const statusFilters = useFormsSelector(
    (state) => state.formBulkUploadImportFile.statusFilters,
  );

  const filteredRows = useFormsSelector((state) =>
    state.formBulkUploadImportFile.validationResult?.rows.filter((row) => {
      if (statusFilters.length === 0) return [];

      return statusFilters.some((filter) => filter === row.status);
    }),
  );

  const selectedTabId = useFormsSelector(
    (state) => state.formBulkUploadImportFile.selectedTabId,
  );

  const locale = useFormsSelector((state) => state.tenantState.tenant.locale);

  const handleFileUpload = useCallback(
    (files: File[]) => {
      dispatch(formBulkUploadImportFileSlice.fileUpload({ file: files[0] }));
    },
    [dispatch],
  );

  const handleFileRejected = useCallback(
    (files: File[], maxSize: number) => {
      dispatch(
        formBulkUploadImportFileSlice.fileRejected({
          file: files[0],
          maxSize,
        }),
      );
    },
    [dispatch],
  );

  const handleWorkflowChange = useCallback(
    (id: FormsBulkUploadWorkflowType) =>
      dispatch(formBulkUploadImportFileSlice.selectWorkflow({ workflow: id })),
    [dispatch],
  );

  const handleCreateAutomatedActionsChange = useCallback(
    (to: boolean) => {
      dispatch(
        formBulkUploadImportFileSlice.toggleCreateAutomatedActions({ to }),
      );
    },
    [dispatch],
  );

  const tabs = [
    {
      id: 'UPLOAD',
      label: 'Upload',
      isEnabled: !fileSelected,
    },
    {
      id: 'WORKFLOW',
      label: 'Workflow',
      isEnabled: selectedTabId === 'WORKFLOW',
    },
    {
      id: 'REVIEW',
      label: 'Review',
      isEnabled: selectedTabId === 'REVIEW',
    },
  ];

  const elError =
    selectedTabId === 'REVIEW' &&
    getValidationMessages(locale, fileSelected, validationResult);

  const statusCounts = useMemo(() => {
    const rows = validationResult?.rows ?? [];

    return {
      error: rows.filter((row) => row.status === 'Error').length,
      success: rows.filter((row) => row.status === 'Success').length,
      warning: rows.filter((row) => row.status === 'Warning').length,
    };
  }, [validationResult]);

  const elUploadTab = selectedTabId === 'UPLOAD' && (
    <UploadTab
      onFileRejected={handleFileRejected}
      onFileUpload={handleFileUpload}
      locale={locale}
    />
  );

  const elWorkflowTab = selectedTabId === 'WORKFLOW' && (
    <WorkflowTab
      onChange={handleWorkflowChange}
      onCreateAutomatedActionsChange={handleCreateAutomatedActionsChange}
      createAutomatedActions={createAutomatedActions}
      includeAutomatedActions={includeAutomatedActions}
    />
  );

  const elReviewTab = selectedTabId === 'REVIEW' && (
    <div>
      {!validationResult && (
        <div css={styles.loadingContainer}>
          <ContentPlaceholder kind={'LIST'} emptyText={''} hintText={''} />
        </div>
      )}
      {validationResult && (
        <ValidationResults
          locale={locale}
          rows={filteredRows}
          statusCounts={statusCounts}
          statusFilters={statusFilters}
        />
      )}
    </div>
  );

  return (
    <div css={styles.base}>
      {isLoading && (
        <div css={styles.loadingMask}>
          <Spinner center={true} />
        </div>
      )}
      <div css={isLoading ? styles.loadingFade : undefined}>
        <div css={styles.header}>
          <div css={styles.title}>
            <Text
              size={48}
              color={color.format(-0.2)}
              weight={FontWeight.light}
            >
              {`Upload ${str.plural(locale.label.form)}`}
            </Text>
          </div>
        </div>
        <div css={styles.body}>
          <div css={styles.tabContent}>
            <div css={styles.tabsContainer}>
              <TabStrip tabs={tabs} selectedId={selectedTabId} />
            </div>
            {elError}
            {elUploadTab}
            {elWorkflowTab}
            {elReviewTab}
          </div>
        </div>
      </div>
    </div>
  );
};

const getValidationMessages = (
  locale: TenantLocale,
  fileSelected: boolean,
  validationResult?: FormsBulkUploadFileValidationResult,
) => {
  if (!fileSelected || !validationResult) return undefined;

  const rowCount = validationResult.rows.length;

  const hasErrors =
    validationResult.errors.length > 0 ||
    (validationResult.rows &&
      validationResult.rows.some((row) => row.errors.length > 0));

  const hasWarnings =
    validationResult.rows &&
    validationResult.rows.some((row) => row.status === 'Warning');

  if (!hasErrors && !hasWarnings) {
    const formLabel = str.plural(locale.label.form, rowCount)?.toLowerCase();
    return (
      <div css={styles.messageBarBase}>
        <MessageBar
          type={'Success'}
          message={`Success: File can be loaded successfully. Click 'Import' to continue, and ${rowCount} ${formLabel} will be created.`}
        />
      </div>
    );
  }

  const elDuplicateRowMessage = getDuplicateRowMessages(validationResult.rows);
  const elHiddenRowMessage = getHiddenFieldsMessages(validationResult.rows);

  const isFailedUpload =
    validationResult.errors.length > 0 ||
    validationResult.rows.every((row) => row.status === 'Error');

  const messageBarText = isFailedUpload
    ? `File Failure: ${toErrorText(locale, validationResult)}`
    : 'Partial Success: File can be loaded, however there are errors. Please review the results below.';

  return (
    <div css={styles.messageBarBase}>
      <MessageBar
        type={isFailedUpload ? 'Error' : 'Warning'}
        message={messageBarText}
      />
      {elDuplicateRowMessage}
      {elHiddenRowMessage}
    </div>
  );
};

const getDuplicateRowMessages = (
  rows: FormsBulkUploadValidateFileUploadRow[],
) => {
  const warningCount = rows.filter((row) =>
    row.errors.some((error) => error.code === 'DUPLICATED_PREVIOUS_IMPORT'),
  ).length;

  if (warningCount === 0) return undefined;

  return (
    <div css={styles.messageBarWarning}>
      <MessageBar
        type={'Warning'}
        message={`Warning: ${warningCount} out of ${rows.length} rows appear to be a duplicate of a previously loaded file.`}
      />
    </div>
  );
};

const getHiddenFieldsMessages = (
  rows: FormsBulkUploadValidateFileUploadRow[],
) => {
  const hiddenRows = rows.filter((row) =>
    row.errors.some((error) => error.code === 'ANSWER_QUESTION_HIDDEN'),
  );

  if (hiddenRows.length === 0) return undefined;

  return (
    <div css={styles.messageBarWarning}>
      <MessageBar
        type={'Warning'}
        message={`Warning: ${hiddenRows.length} out of ${rows.length} rows appear to be skipped.`}
      />
    </div>
  );
};

const toErrorText = (
  locale: TenantLocale,
  validationResult: FormsBulkUploadFileValidationResult,
): string => {
  if (
    !validationResult.errors.length &&
    validationResult.rows.every((row) => row.status === 'Error')
  ) {
    return 'All rows have errors.';
  }

  const errorOutcomes = validationResult.errors.map((error) => {
    const localizedOutcome = locale.forms.bulkUpload[error.code];
    const args = toFormatArgs(locale, error.payload);

    return (
      (localizedOutcome &&
        tryStringFormat(error.code, localizedOutcome, args)) ??
      str.humanize(error.code)
    );
  });

  return errorOutcomes.length ? errorOutcomes.join(' ') : '';
};

const styles = {
  base: css({
    overflow: 'hidden',
    width: '100%',
    flex: '1 1 auto',
    position: 'relative',
  }),
  loadingMask: css({
    position: 'absolute',
    display: 'flex',
    inset: 0,
    zIndex: 1,
  }),
  loadingFade: css({
    opacity: 0.5,
    pointerEvents: 'none',
  }),
  body: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    padding: '0 25px 25px 25px',
  }),
  header: css({
    height: 96,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'stretch',
  }),
  messageBarBase: css({
    padding: '15px 0',
  }),
  messageBarWarning: css({
    paddingTop: '10px',
  }),
  tabContent: css({
    flex: '1 1 auto',
    maxWidth: '1085px',
    height: '100%',
  }),
  tabsContainer: css({
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    background: color.format(1),
    width: '100%',
    left: 0,
    padding: '0 20px',
  }),
  title: css({
    flex: '1 1 auto',
    margin: '20px 0 0 34px',
  }),
  loadingContainer: css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 100,
    width: '100%',
    textAlign: 'center',
    paddingTop: 50,
  }),
};
