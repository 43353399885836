/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ContentPlaceholder } from '@seeeverything/ui.primitives/src/components/ContentPlaceholder/ContentPlaceholder.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useTrackInViewport } from '@seeeverything/ui.primitives/src/hooks/useTrackInViewport.ts';
import { useSegmentAnalytics } from '@seeeverything/ui.util/src/analytics/SegmentProvider.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { propsAreEqualFastCompare } from '@seeeverything/ui.util/src/react/memoFastCompare.ts';
import { memo, useEffect, useState } from 'react';
import { DASHBOARD_INTERSECTION_OPTIONS } from '../common/constants.ts';
import { useDashboardsSelector } from '../redux/store.ts';

type FactoryIssuesInsightsContainerProps = {
  id: string;
  parentId?: string;
};

const View: React.FC<FactoryIssuesInsightsContainerProps> = ({
  id,
  parentId,
}) => {
  const { track } = useSegmentAnalytics();

  const [forwardedRef, inViewport] = useTrackInViewport({
    dataId: id,
    parentDataId: parentId,
    observerOptions: DASHBOARD_INTERSECTION_OPTIONS,
  });

  const [isInit, setIsInit] = useState(inViewport);

  useEffect(() => {
    if (isInit) return;
    if (!inViewport) return;
    setIsInit(true);
  }, [inViewport, isInit]);

  const template = useDashboardsSelector(
    (state) => state.dashboardsV2.template,
  );

  const issuesInsights = useDashboardsSelector(
    (state) => state.dashboardsV2.ISSUES_AND_INSIGHTS[id],
  );

  const [isQueryEnabled, setIsQueryEnabled] = useState(false);
  useEffect(() => {
    if (issuesInsights && template && inViewport && !isQueryEnabled) {
      track('dashboard_section_load', { sectionLabel: id });
      setIsQueryEnabled(true);
    }
  }, [id, inViewport, isQueryEnabled, issuesInsights, template, track]);

  const elInitPlaceholder = !isQueryEnabled && (
    <div css={styles.loadingPlaceholder}>
      <ContentPlaceholder kind={'LIST'} emptyText={''} hintText={''} />
    </div>
  );

  const elIssuesInsights = isQueryEnabled && (
    <Text
      color={color.format(-0.65)}
    >{`Issues and Insights: ${issuesInsights.type}`}</Text>
  );

  return (
    <div
      data-id={id}
      parent-data-id={parentId}
      ref={forwardedRef}
      css={styles.base}
    >
      {elIssuesInsights}
      {elInitPlaceholder}
    </div>
  );
};

const styles = {
  base: css({
    margin: '30px 30px 30px 35px',
  }),
  loadingPlaceholder: css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 300,
    width: '100%',
    textAlign: 'center',
    paddingTop: 50,
  }),
};

export const FactoryIssuesInsightsContainer = memo(
  View,
  propsAreEqualFastCompare,
);
