/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { PaperProps } from '@material-ui/core';
import { useQueryFormTemplatesByCategory } from '@se/data/forms/hooks/useQueryFormTemplatesByCategory.ts';
import { serverCreate } from '@seeeverything/ui.forms/src/redux/form-instance/instance/actions.ts';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Card } from '@seeeverything/ui.primitives/src/components/Card/Card.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import {
  Popper,
  PopperProps,
} from '@seeeverything/ui.primitives/src/components/Popper/Popper.tsx';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import {
  TextFieldDropdown,
  TextFieldDropdownCloseHandler,
} from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';
import { makeStyles } from '@seeeverything/ui.primitives/src/hooks/makeStyles.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import { useCallback, useRef, useState } from 'react';
import { chipAddedExternally } from '../../redux/query/actions.ts';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';

export const CreateNewFormInstanceButton: React.FC = () => {
  const dispatch = useShellDispatch();

  const formLabel = useShellSelector(
    (state) => state.tenantState.tenant.locale.label.form,
  );

  const { templateSelections, templatesLoading } =
    useQueryFormTemplatesByCategory({ createInstanceOnly: true });

  const handleCreateInstance = useCallback(
    (to: ISelectionListItem) => {
      const template = to.value;
      const instanceId = uuid.generate();

      dispatch(
        serverCreate({
          categoryName: template.category.name,
          instanceId,
          templateDefinitionId: template.currentDefinitionId,
          templateId: template.id,
          templateName: template.name,
        }),
      );
      dispatch(
        chipAddedExternally(
          { type: 'forms', label: template.name, value: instanceId },
          true,
          'NEW_FORM',
        ),
      );
    },
    [dispatch],
  );

  const [isFocused, setIsFocused] = useState(false);

  const inputRef = useRef<HTMLInputElement>();

  const inputClasses = useInputStyles(isFocused)();
  const inputLabelClasses = useInputLabelStyles();

  const handleButtonClick = () => inputRef.current?.focus();

  const handleInputFocus = () => setIsFocused(true);
  const handleInputBlur = () => setIsFocused(false);

  const handlePopperClose: TextFieldDropdownCloseHandler = (_, reason) => {
    if (reason === 'toggleInput') return;
    setIsFocused(false);
  };

  const PaperComponent = ({ children }: PaperProps) => (
    <Card
      backgroundColor={1}
      style={{ marginTop: 0, border: `1px solid ${color.format(-0.18)}` }}
      childContainerStyle={styles.paper}
    >
      {children}
    </Card>
  );

  const PopperComponent = (props: PopperProps) => (
    <Popper
      {...props}
      style={{ width: 400 }}
      placement={'bottom-start'}
      data-test={'shell-newFormField-popper'}
    />
  );

  const elInputField = (
    <TextFieldDropdown
      id={'form-templates'}
      label={`New ${formLabel}`}
      onChange={handleCreateInstance}
      selections={templateSelections}
      size={'small'}
      openOnFocus={true}
      variant={'filled'}
      listBoxStyle={styles.fieldListBox}
      popperStyle={styles.fieldPopper}
      isLoading={templatesLoading}
      InputProps={{
        inputRef,
        classes: inputClasses,
        disableUnderline: true,
        endAdornment: undefined,
        onFocus: handleInputFocus,
        onBlur: handleInputBlur,
        placeholder: 'Filter',
        style: styles.fieldInput,
      }}
      InputLabelProps={{
        classes: inputLabelClasses,
        style: {
          height: '100%',
          minHeight: 19,
        },
      }}
      PaperComponent={PaperComponent}
      PopperComponent={PopperComponent}
      isOpen={isFocused}
      onClose={handlePopperClose}
    />
  );

  return (
    <Button
      style={styles.base}
      onClick={handleButtonClick}
      dataTest={'shell-newFormField-newFormButton'}
    >
      <>
        <Icons.addBox fill={COLORS.BLUE} />
        {elInputField}
      </>
    </Button>
  );
};

const useInputStyles = (isFocused: boolean) =>
  makeStyles({
    root: {
      paddingTop: '0px !important',
      '&:hover': {
        borderBottom: 'none',
      },
    },
    input: {
      cursor: isFocused ? 'text' : 'pointer',
    },
  });

const useInputLabelStyles = makeStyles({
  root: {
    color: COLORS.BLUE,
    position: 'relative',
    paddingRight: 11,
    paddingLeft: 8,
    transform: 'translate(0px, 16px) scale(1) !important',
  },
  shrink: {
    transform: 'translate(6px, 6px) scale(0.75) !important',
  },
});

const styles = {
  base: css({
    display: 'flex',
    cursor: 'pointer',
    paddingLeft: 8,
    borderRadius: 3,
    '&:hover': {
      background: color.format(-0.08),
    },
  }),
  fieldInput: {
    paddingRight: 8,
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
  fieldListBox: {
    maxHeight: '70vh',
  },
  fieldPopper: {
    left: '-32px !important',
  },
  paper: css({ display: 'flex', flexDirection: 'column' }),
};
