/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FONTS } from '@seeeverything/ui.primitives/src/common/constants.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { IIcon } from '@seeeverything/ui.primitives/src/components/Icon/types.ts';
import { InputField } from '@seeeverything/ui.primitives/src/components/InputField/InputField.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import React from 'react';
import {
  Subject,
  delay,
  distinctUntilKeyChanged,
  filter,
  takeUntil,
} from 'rxjs';
import {
  ChipKey,
  IEntityDictionary,
} from '../../../../api/api.queryBuilder/types.ts';
import { getEntity } from '../../common/entity.ts';
import {
  ChipChangeEventHandler,
  ChipEventHandler,
  ChipEventHandlerWithReasons,
  ChipKeyDownEventHandler,
  OnCompleteReason,
} from './types.ts';

export interface IQueryChipProps {
  entityDictionary: IEntityDictionary;
  chipKey: ChipKey;

  type: string;
  hintType?: string;
  value?: string;
  label?: string;
  icon?: IIcon;

  // States.
  canEdit?: boolean;
  isEditing?: boolean;
  isSelected?: boolean;
  isTransparent?: boolean;
  isActive?: boolean; // Controls whether the chip will respond to meta events (enter, etc.).

  onChange?: ChipChangeEventHandler;
  onComplete?: ChipEventHandlerWithReasons<OnCompleteReason>;
  onSelect?: ChipEventHandler;
  onStartEditing?: ChipEventHandler;
  onStopEditing?: ChipEventHandler;
  onDelete?: ChipEventHandler;
  onKeyDown?: ChipKeyDownEventHandler;
  onSelectPrev?: ChipEventHandler;
  onSelectNext?: ChipEventHandler;
  onShowDropdown?: ChipEventHandler;

  // Options.
  alwaysDisplayType?: boolean;
}

const FONT_SIZE = 14;
const BG_COLOR = '#4A90E2';

const DROPDOWN_ICON_FILL = color.create(COLORS.BLUE).alpha(0.5).css();
const DROPDOWN_ICON_FILL_TRANSPARENT = color.format(0.5);

/**
 * A Search Box Chip
 */
export class QueryChip extends React.Component<IQueryChipProps, object> {
  public static defaultProps: Partial<IQueryChipProps> = {
    canEdit: true,
  };

  private unmounted$ = new Subject<void>();
  private props$ = new Subject<IQueryChipProps>();

  private chip: HTMLElement;
  private chipRef = (component: HTMLDivElement) => (this.chip = component);

  public UNSAFE_componentWillMount() {
    const props$ = this.props$.pipe(takeUntil(this.unmounted$));

    // Fire start-editing events on load if required.
    if (this.props.isEditing) {
      this.handleStartEditing();
    }

    // Ensure chip is selected.
    props$
      .pipe(
        distinctUntilKeyChanged('isSelected'),
        filter((props) => props.isSelected === true),
        delay(0),
      )
      .subscribe(() => this.selectChip());

    // Fire editing stopped when chip loses selection.
    props$
      .pipe(
        distinctUntilKeyChanged('isEditing'),
        filter(() => this.props.isEditing === true), // Is currently editing.
        filter((props) => props.isEditing === false), // Stopping editing.
      )
      .subscribe(() => {
        const { onStopEditing, chipKey } = this.props;
        if (onStopEditing) {
          onStopEditing(chipKey);
        }
      });

    // Initialize.
    this.props$.next(this.props);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IQueryChipProps) {
    this.props$.next(nextProps);
  }

  public componentWillUnmount() {
    this.unmounted$.next(null);
  }

  public getPosition() {
    const chipRef = this.chip;
    const position: {
      [T in 'left' | 'top' | 'right' | 'bottom']?: number;
    } = {};
    if (chipRef) {
      const boundingClient = chipRef.getBoundingClientRect();
      position.left = Math.round(boundingClient.left);
      position.top = Math.round(boundingClient.top);
      position.right = Math.round(boundingClient.right);
      position.bottom = Math.round(boundingClient.bottom);
    }
    return {
      chip: chipRef ? position : undefined,
    };
  }

  private get entity() {
    const { entityDictionary, type, value } = this.props;
    return getEntity({ entities: entityDictionary, type, value });
  }
  private get entityLabel() {
    const entity = this.entity;
    return entity && entity.label;
  }

  public render() {
    const {
      hintType = '',
      type = '',
      value = '',
      isTransparent = false,
      canEdit,
    } = this.props;

    const isEditing = isTransparent ? false : this.props.isEditing;
    const isSelected = isTransparent ? false : this.props.isSelected;
    const isNotPeopleOrTeamType = type !== 'people' && type !== 'team';
    const entity = this.entity;

    const Icon = this.props.icon || (entity && entity.icon);
    const elIcon = Icon ? (
      <Icon size={18} fill={isTransparent ? 'white' : COLORS.BLUE} />
    ) : undefined;

    const backgroundColor = isTransparent
      ? color.create(COLORS.WHITE).alpha(0.24).css()
      : isSelected
        ? color.create(BG_COLOR).alpha(0.3).css()
        : color.create(BG_COLOR).alpha(0.1).css();

    const computedStyles = {
      main: css({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        borderRadius: 2,
        height: 24,
        ':focus': {
          outline: 'none',
        },
        marginTop: 1,
        marginBottom: 1,
        backgroundColor,
        border: isTransparent
          ? '1px solid rgba(255, 255, 255, 0.33)'
          : `1px solid ${color.create(BG_COLOR).alpha(0.11).css()}`,
        paddingRight: isEditing ? 5 : 0,
        maxWidth: isNotPeopleOrTeamType ? 270 : 'none',
      }),
    };

    const restOfHintType = (() => {
      if (hintType.length === 0 || hintType.length <= type.length) {
        return '';
      }
      const charsOverlap = hintType.length - type.length;
      return hintType.slice(-charsOverlap);
    })();

    const elTypeLabel = (
      <Text
        key={'type'}
        color={isTransparent ? COLORS.WHITE : 'black'}
        weight={400}
        size={FONT_SIZE}
        style={styles.labelTextStyle}
      >
        {this.entityLabel}
      </Text>
    );
    const elHintTypeLabel = (
      <Text
        key={'hint'}
        color={'grey'}
        weight={300}
        style={styles.labelTextStyle}
      >
        {restOfHintType}
      </Text>
    );
    const elColon = (
      <Text
        key={'colon'}
        color={isTransparent ? COLORS.WHITE : COLORS.BLUE}
        weight={400}
        size={FONT_SIZE}
        marginRight={5}
        style={styles.labelTextStyle}
      >
        {':'}
      </Text>
    );

    const valueText = this.props.label || value || this.entityLabel;
    const elValueLabel = (
      <Text
        key={'value'}
        color={isTransparent ? COLORS.WHITE : COLORS.BLACK}
        weight={400}
        size={FONT_SIZE}
        style={css([styles.labelTextStyle, styles.valueTextStyle])}
      >
        {valueText}
      </Text>
    );

    const inputText = this.props.label || '';
    const elExpandingValueInput = (
      <InputField
        key={'valueInput'}
        value={inputText}
        onChange={this.handleEditorValueChange}
        style={styles.valueInput}
      />
    );

    const fill = canEdit
      ? isTransparent
        ? DROPDOWN_ICON_FILL_TRANSPARENT
        : DROPDOWN_ICON_FILL
      : 'rgba(0, 0, 0, 0)';

    const elDropdownIconButton = canEdit ? (
      <>
        <Icons.arrowDropDown
          fill={fill}
          size={20}
          cursor={isTransparent ? 'default' : 'pointer'}
        />
      </>
    ) : (
      <div key={'dropdownIcon'} css={styles.iconOuter} /> // Keeps non drop down chips nicely spaced.
    );

    const children = isEditing
      ? [elTypeLabel, elHintTypeLabel, elColon, elExpandingValueInput]
      : this.props.alwaysDisplayType
        ? [elTypeLabel, elColon, elValueLabel, elDropdownIconButton]
        : [elValueLabel, elDropdownIconButton];

    return (
      <div
        ref={this.chipRef}
        css={computedStyles.main}
        onClick={this.handleMouseDown}
        onKeyDown={this.handleChipKeyDown}
        tabIndex={0}
      >
        <div css={styles.mainInner}>
          <div css={styles.iconOuter}>{elIcon}</div>
        </div>
        {children}
      </div>
    );
  }

  private selectChip = () => {
    if (this.chip) {
      this.chip.focus();
    }
  };

  private handleEditorValueChange = (e: any) => {
    const { onChange, value, chipKey } = this.props;
    if (onChange) {
      onChange({
        key: chipKey,
        value,
        label: e.to,
      });
    }
  };

  private handleChipKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    const { chipKey, isEditing, onSelectNext, onSelectPrev, onDelete } =
      this.props;
    if ((e.key === 'Backspace' || e.key === 'Delete') && !isEditing) {
      e.preventDefault();
      if (onDelete) {
        onDelete(chipKey);
      }
    }
    if (!isEditing && (e.key === 'Enter' || e.key === 'ArrowDown')) {
      e.preventDefault();
      this.handleShowDropdown();
    }

    if (isEditing) {
      if (e.key === 'ArrowDown') {
        e.preventDefault(); // Prevent moving edit caret to beginning.
      }
      if (e.key === 'ArrowUp') {
        e.preventDefault(); // Prevent moving edit caret to end.
      }
    }

    if (!isEditing) {
      if (e.key === 'ArrowLeft' && onSelectPrev) {
        onSelectPrev(chipKey);
      }
      if (e.key === 'ArrowRight' && onSelectNext) {
        onSelectNext(chipKey);
      }
    }
  };

  private handleMouseDown = (e: React.MouseEvent<HTMLElement>) => {
    const { isEditing, onSelect } = this.props;
    if (!isEditing) {
      e.preventDefault();
      e.stopPropagation();
      if (onSelect) {
        onSelect(this.props.chipKey);
      }
    }

    this.handleDropdownClick();
  };

  private handleDropdownClick = () => this.handleShowDropdown();

  private handleShowDropdown = () => {
    const { onShowDropdown, chipKey, canEdit } = this.props;
    if (onShowDropdown) {
      onShowDropdown(chipKey);
    }
    if (canEdit) {
      this.handleStartEditing();
    }
  };
  private handleStartEditing = () => {
    const { onStartEditing, chipKey, canEdit } = this.props;
    if (canEdit && onStartEditing) {
      onStartEditing(chipKey);
    }
  };
}

const styles = {
  mainInner: css({
    display: 'flex',
  }),
  labelTextStyle: css({
    userSelect: 'none',
    cursor: 'default',
    whiteSpace: 'nowrap',
  }),
  valueTextStyle: css({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: 0,
    cursor: 'default',
  }),
  valueInput: {
    outline: 'none',
    background: 'none',
    border: 'none',
    fontFamily: FONTS.Roboto.family,
    fontSize: FONT_SIZE,
  },
  iconOuter: css({
    padding: '3px 5px',
    display: 'flex',
    cursor: 'default',
  }),
};
