/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormGoalCategory } from '@se/data/forms/types.ts';
import { SimpleEntity } from '@se/data/types.ts';
import { DatePicker } from '@seeeverything/ui.primitives/src/components/DatePicker/DatePicker.tsx';
import { IconWrapper } from '@seeeverything/ui.primitives/src/components/IconWrapper/IconWrapper.tsx';
import {
  IListItemLabel,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { TextFieldDropdown } from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { GoalAdditionalField } from '@seeeverything/ui.util/src/redux/tenant/types.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import moment from 'moment';
import { isEmpty } from 'ramda';
import { useCallback, useMemo } from 'react';
import { PersonSubject } from '../../query/types.ts';
import { FormGoalStatus, GoalNote } from '../../redux/form-edit-goal/types.ts';
import { useFormsSelector } from '../../redux/store.ts';
import {
  IPeopleDropdownContainerProps,
  PeopleDropdownContainer,
} from '../PeopleDropdown/PeopleDropdownContainer.tsx';
import { PreviousResponsesDropdown } from '../PreviousAnswersDropdown/PreviousAnswersDropdown.tsx';

export interface IGoalDialogProps {
  additionalFields: GoalAdditionalField[];
  assignedTo: SimpleEntity;
  canChangeGoalCategory?: boolean;
  canShowPreviousAnswers: boolean;
  completedAt?: string;
  description: string;
  dueBy: string;
  goalCategory?: FormGoalCategory;
  goalCategorySelections: ISelectionListItem<
    IListItemLabel,
    FormGoalCategory
  >[];
  hasGoalCategories: boolean;
  instanceSubject?: PersonSubject;
  isEditable: boolean;
  minimumDueDate?: string;
  note?: string;
  onAdditionalFieldsChange: (update: GoalAdditionalField) => void;
  onAssignedToChange: (to: SimpleEntity) => void;
  onDescriptionChange: (to: string) => void;
  onDueByChange: (to: string) => void;
  onGoalCategoryChange: (to: FormGoalCategory) => void;
  onNoteChange: (to: string) => void;
  onShowPreviousGoalNotes?: () => void;
  onStatusChange: (to: FormGoalStatus) => void;
  previousNotesLoading?: boolean;
  previousNotes?: GoalNote[];
  showAssignedToValidation: boolean;
  showDescriptionValidation: boolean;
  showDueByValidation: boolean;
  showGoalCategoryValidation: boolean;
  status: FormGoalStatus;
  statusSelections: ISelectionListItem<IListItemLabel, FormGoalStatus>[];
}

/**
 * The dialog content component for creating and modifying a goal.
 */
export const GoalDialog: React.FC<IGoalDialogProps> = ({
  additionalFields,
  assignedTo,
  canChangeGoalCategory,
  canShowPreviousAnswers,
  completedAt,
  description,
  dueBy,
  goalCategory,
  hasGoalCategories,
  instanceSubject,
  isEditable,
  minimumDueDate,
  note,
  onAdditionalFieldsChange,
  onAssignedToChange,
  onDescriptionChange,
  onDueByChange,
  onGoalCategoryChange,
  onNoteChange,
  onShowPreviousGoalNotes,
  onStatusChange,
  previousNotes,
  previousNotesLoading,
  showAssignedToValidation,
  showDescriptionValidation,
  showDueByValidation,
  showGoalCategoryValidation,
  status,
  statusSelections,
}) => {
  const tenantConfig = useFormsSelector(
    (state) => state.tenantState.tenant.configuration,
  );

  const goalCategorySelection = useMemo(
    (): ISelectionListItem<IListItemLabel> =>
      goalCategory
        ? { id: goalCategory.id, content: { text: goalCategory.name } }
        : { id: null, content: { text: 'Goal' } },
    [goalCategory],
  );

  const handleAssignedToChange = useCallback<
    IPeopleDropdownContainerProps['onPersonSelected']
  >(
    (to) => {
      onAssignedToChange({
        id: to.id,
        name: `${to.firstName} ${to.lastName}`.trim(),
      });
    },
    [onAssignedToChange],
  );

  const handleStatusChange = useCallback(
    (to: ISelectionListItem) => {
      onStatusChange(to.id as FormGoalStatus);
    },
    [onStatusChange],
  );

  const handleGoalCategoryChange = useCallback(
    (to: ISelectionListItem<IListItemLabel>) => {
      onGoalCategoryChange({ id: to.id as string, name: to.content.text });
    },
    [onGoalCategoryChange],
  );

  const handleAdditionalFieldUpdate = useCallback(
    (field: GoalAdditionalField) => (to: string) =>
      onAdditionalFieldsChange({ ...field, value: to }),
    [onAdditionalFieldsChange],
  );

  const elAdditionalFields = additionalFields
    .slice()
    .sort(
      (a, b) =>
        tenantConfig.goalAdditionalFields?.indexOf(a) -
        tenantConfig.goalAdditionalFields?.indexOf(b),
    )
    .map((field) => (
      <TextField
        key={field.key}
        id={field.key}
        value={field.value}
        label={field.label}
        onChange={handleAdditionalFieldUpdate(field)}
        multiline={true}
        maxRows={5}
        isEnabled={isEditable}
        style={styles.items}
      />
    ));

  const elNoteField = (
    <TextField
      id={'note'}
      isEnabled={isEditable}
      label={'Add a Note'}
      maxRows={5}
      multiline={true}
      onChange={onNoteChange}
      style={styles.noteField}
      value={note}
    />
  );

  const elNotes = canShowPreviousAnswers ? (
    <PreviousResponsesDropdown
      previousResponses={(previousNotes ?? []).map((previousNote) => ({
        id: previousNote.id,
        description: previousNote.note,
        value: previousNote.note,
        heading: `Added on ${moment(previousNote.createdAt).format(
          'D MMM',
        )} by ${previousNote.createdBy.name}`,
      }))}
      dropdownTitle={'Previous Notes'}
      emptyMessage={'No previous notes to display.'}
      isLoading={previousNotesLoading}
      isFilterable={!isEmpty(previousNotes)}
      linkLabel={'Show Previous Notes'}
      onAnswersDropdownShow={onShowPreviousGoalNotes}
    >
      {elNoteField}
    </PreviousResponsesDropdown>
  ) : (
    elNoteField
  );

  const elLeftChildren = (
    <>
      <TextField
        dataTest={'forms-goalDialog-descriptionField'}
        id={'description'}
        value={description}
        label={'Description'}
        onChange={onDescriptionChange}
        multiline={true}
        maxRows={10}
        isEnabled={isEditable}
        error={
          showDescriptionValidation ? 'This field is required.' : undefined
        }
        style={styles.items}
      />
      {elAdditionalFields}
    </>
  );

  const elRightChildren = (
    <>
      {hasGoalCategories && (
        <IconWrapper icon={'list'} style={styles.items}>
          <TextFieldDropdown
            id={`goalCategory`}
            isEnabled={isEditable && canChangeGoalCategory}
            value={goalCategorySelection}
            label={'Type'}
            onChange={handleGoalCategoryChange}
            error={
              showGoalCategoryValidation ? 'This field is required.' : undefined
            }
          />
        </IconWrapper>
      )}
      <IconWrapper icon={'notifications'} style={styles.items}>
        <DatePicker
          dataTest={'forms-goalDialog-dueDatePicker'}
          id={'due'}
          isEnabled={isEditable}
          value={dueBy}
          onChange={onDueByChange}
          label={'Due Date'}
          minDate={minimumDueDate}
          error={showDueByValidation ? 'This field is required.' : undefined}
          outputDate={'TenantEndOfDay'}
        />
      </IconWrapper>
      <IconWrapper icon={'face'} style={styles.items}>
        <PeopleDropdownContainer
          id={'goalAssignedTo'}
          isEnabled={isEditable}
          selectedPerson={assignedTo}
          label={'Assigned To'}
          onPersonSelected={handleAssignedToChange}
          source={'ACTION_PLAN'}
          subject={instanceSubject}
          error={
            showAssignedToValidation ? 'This field is required.' : undefined
          }
        />
      </IconWrapper>
      <IconWrapper icon={'info'} style={styles.items}>
        <TextFieldDropdown
          id={'goalStatus'}
          isEnabled={true}
          value={status ? { id: status, content: status } : undefined}
          label={'Status'}
          onChange={handleStatusChange}
          selections={statusSelections}
        />
      </IconWrapper>
      {completedAt && (
        <IconWrapper icon={'done'} style={styles.items}>
          <DatePicker
            id={'completedAt'}
            isEnabled={false}
            value={completedAt}
            label={'Completed Date'}
          />
        </IconWrapper>
      )}
    </>
  );

  return (
    <div css={styles.base}>
      <div css={styles.header}>
        <div css={styles.title}>
          <Text size={48} color={color.format(-0.2)} weight={FontWeight.light}>
            {'Goal'}
          </Text>
        </div>
      </div>
      <div css={styles.body}>
        <div css={styles.bodyColumns}>
          <div css={styles.bodyLeft}>{elLeftChildren}</div>
          <div css={styles.bodyRight}>{elRightChildren}</div>
        </div>
        <div css={styles.bodyNotes}>{elNotes}</div>
      </div>
    </div>
  );
};

const styles = {
  base: css({
    width: '100%',
    height: '100%',
  }),
  header: css({
    height: 96,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'stretch',
  }),
  title: css({
    flex: '1',
    margin: '20px 0 0 34px',
  }),
  body: css({
    flex: '1 1 auto',
    padding: '0 33px 33px 33px',
  }),
  bodyColumns: css({
    display: 'flex',
  }),
  bodyLeft: css({
    width: '60%',
    paddingRight: 33,
  }),
  bodyRight: css({
    width: '40%',
    paddingRight: 14,
  }),
  bodyNotes: css({
    width: '100%',
  }),
  items: css({
    marginBottom: 10,
    paddingBottom: 12,
  }),
  noteField: css({
    marginBottom: 10,
  }),
};
