/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { DatePicker } from '@seeeverything/ui.primitives/src/components/DatePicker/DatePicker.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { IconWrapper } from '@seeeverything/ui.primitives/src/components/IconWrapper/IconWrapper.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { TextFieldDropdown } from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { useCallback, useMemo } from 'react';
import { editDistributionListSlice } from '../../../redux/editDistributionList/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../../redux/store.ts';
import { DistributionListRulesFieldContainer } from './DistributionListRulesField.tsx';

const RULE_TYPE_PERSON = {
  id: 'Person',
  content: {
    text: 'Person',
    description: 'Create a list of people.',
  },
  dataTest: 'Person',
  value: 'Person',
  icon: Icons.face,
};

const RULE_TYPE_TEAM = {
  id: 'Team',
  content: {
    text: 'Team',
    description: 'Create a list of teams.',
  },
  dataTest: 'Team',
  value: 'Team',
  icon: Icons.team,
};

/**
 * Fields for editing a form schedule
 */
export const DistributionListFieldsContainer: React.FC = () => {
  const dispatch = useFormsDispatch();

  const draft = useFormsSelector(
    (state) => state.formEditDistributionList.draft,
  );

  const isNew = draft.id === undefined;

  const module = useFormsSelector((state) => state.tenantState.tenant.module);

  const validationErrors = useFormsSelector(
    (state) => state.formEditDistributionList.errors?.fieldErrors,
  );

  const ruleTypeSelections = useMemo(() => {
    if (module === 'cadence') return [RULE_TYPE_TEAM];
    if (module === 'coaching') return [RULE_TYPE_PERSON];
    return [RULE_TYPE_TEAM, RULE_TYPE_PERSON];
  }, [module]);

  const handleFieldChanged = useCallback(
    (field: 'listName' | 'status' | 'type') => (value: string) =>
      dispatch(
        editDistributionListSlice.updateDraftDistributionList({
          field,
          value,
        }),
      ),
    [dispatch],
  );

  const toSelectionItem = useCallback(
    (value: string) => ({
      id: value,
      content: { text: value },
      value: value,
    }),
    [],
  );

  return (
    <div
      css={css({ display: 'flex', flexDirection: 'column', flex: '1 1 auto' })}
    >
      <Text style={styles.title}>{'Distribution List'}</Text>
      <IconWrapper icon={'message'} style={styles.field}>
        <TextField
          id={'Name'}
          label={'Name'}
          value={draft.listName}
          isEnabled={draft.canEdit === true}
          onChange={handleFieldChanged('listName')}
          error={
            validationErrors?.nameField && !draft.listName
              ? validationErrors?.nameField
              : undefined
          }
        />
      </IconWrapper>
      <IconWrapper icon={'howToReg'} style={styles.dropdown}>
        <TextFieldDropdown
          dataTest={'distribution-list-type-selector'}
          id={'List Type'}
          label={'List Type'}
          isEnabled={Boolean(
            draft.canEdit === true &&
              ruleTypeSelections.length > 1 &&
              !draft.rules.length,
          )}
          selections={ruleTypeSelections}
          onChange={(e) => handleFieldChanged('type')(e.value)}
          value={toSelectionItem(draft.type)}
          error={validationErrors?.typeField}
        />
      </IconWrapper>
      <IconWrapper icon={'list'} style={styles.rules}>
        <DistributionListRulesFieldContainer
          label={'Rules'}
          rules={draft.rules}
          type={draft.type}
        />
      </IconWrapper>
      {!isNew && (
        <>
          <IconWrapper icon={'face'} style={styles.field}>
            <TextField
              id={'Last Updated By'}
              label={'Last Updated By'}
              value={draft.lastUpdatedBy}
              isEnabled={false}
            />
          </IconWrapper>
          <IconWrapper icon={'dateRange'} style={styles.field}>
            <DatePicker
              id={'Last Updated On'}
              isEnabled={false}
              value={draft.lastUpdatedOn}
              label={'Last Updated On'}
            />
          </IconWrapper>
        </>
      )}
    </div>
  );
};

const styles = {
  title: css({
    fontWeight: 900,
    fontSize: 18,
    color: color.format(-0.6),
    padding: '20px 5px 20px 5px',
    display: 'flex',
  }),
  field: css({
    flex: '1 1 auto',
    padding: 5,
  }),
  dropdown: css({
    flex: '0 1 50%',
    padding: 5,
  }),
  rules: css({
    padding: 5,
  }),
};
