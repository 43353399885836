/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CheckboxGroup } from '@seeeverything/ui.primitives/src/components/CheckboxRadioGroup/CheckboxGroup.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import Transition from '@seeeverything/ui.primitives/src/components/Transition/Transition.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { useCallback } from 'react';

export interface IIssueSecondaryCauseProps {
  canEdit: boolean;
  issueCauseId: string;
  label: string;
  details: string;
  isSelected: boolean;
  onCauseToggled: (issueCauseId: string) => void;
  onCauseDetailsChanged: (issueCauseId: string, to: string) => void;
  showValidationError: boolean;
}

export const IssueSecondaryCause = ({
  canEdit,
  issueCauseId,
  label,
  details,
  isSelected,
  onCauseToggled,
  onCauseDetailsChanged,
  showValidationError,
}: IIssueSecondaryCauseProps) => {
  const toggleCause = useCallback(() => {
    onCauseToggled(issueCauseId);
  }, [issueCauseId, onCauseToggled]);

  const updateDetails = useCallback(
    (to: string) => {
      onCauseDetailsChanged(issueCauseId, to);
    },
    [issueCauseId, onCauseDetailsChanged],
  );

  return (
    <div css={styles.base}>
      <CheckboxGroup
        direction={'vertical'}
        isEnabled={canEdit}
        onChange={toggleCause}
        options={[{ id: issueCauseId, label }]}
        value={[{ id: issueCauseId, checked: isSelected }]}
        style={styles.checkboxOption}
      />
      <Transition.Fade
        in={isSelected}
        mountOnEnter={true}
        unmountOnExit={true}
        timeout={100}
      >
        <div css={styles.detailsGroup}>
          <Icons.chevronRight fill={color.format(-0.8)} />
          <TextField
            id={label}
            placeholder={'Please provide more details here.'}
            multiline={true}
            style={styles.textField}
            onChange={updateDetails}
            value={details}
            error={showValidationError ? 'This is required.' : undefined}
            isEnabled={canEdit}
          />
        </div>
      </Transition.Fade>
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    ':hover': {
      backgroundColor: color.format(-0.06),
      borderRadius: 4,
    },
  }),
  checkboxOption: css({
    flex: '1 0 275px',
    paddingLeft: 15,
  }),
  detailsGroup: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    flex: '1 1 100%',
  }),
  textField: css({
    padding: 8,
  }),
};
